function customLightSlider() {
    $('.lightSlider').lightSlider ({
        gallery: false,
        item: 1,
        mode: 'fade',
        loop: false,
        slideMargin: 2,
        enableTouch: false,
        thumbItem: 12,
        controls: false,
        responsive: [
            {
                breakpoint: 992,
                settings: {
                    item: 3,
                    mode: 'slide',
                    enableTouch: false,
                    controls: false,
                    gallery: false,
                    slideMargin: 2,
                }
            },
            {
                breakpoint: 626,
                settings: {
                    item:2,
                    mode: 'slide',
                    enableTouch: false,
                    controls: false,
                    gallery: false,
                    slideMargin: 2,
                }
            },
            {
                breakpoint: 442,
                settings: {
                    item: 1,
                    mode: 'slide',
                    enableTouch: false,
                    controls: false,
                    gallery: false,
                }
            }
        ]
    });
}

function imgZoomOverlay() {
    $(function() {
        // OPACITY OF BUTTON SET TO 0%
        $('.img-zoom').css('opacity','0');

        // ON MOUSE OVER
        $('.img-zoom').hover(function () {
            // SET OPACITY TO 70%
            $(this).stop().animate({ opacity: 1 }, 'fast');
        },

        // ON MOUSE OUT
        function () {
            // SET OPACITY BACK TO 50%
            $(this).stop().animate({ opacity: 0 }, 'slow');
        });
    });
}

$(document).ready( function(){
    $('form input, form textarea').addClass('form-control');

    $('body').scrollspy({ target: '#navbar-example' });

    imgZoomOverlay();
    customLightSlider();

});
